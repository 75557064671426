export default {
  visdat: { "11743f672f44": ["function () ", "plotlyVisDat"] },
  cur_data: "11743f672f44",
  attrs: {
    "11743f672f44": {
      alpha_stroke: 1,
      sizes: [10, 100],
      spans: [1, 20],
      x: {},
      y: {},
      color: {},
      type: "scatter",
      mode: "line",
      inherit: true,
    },
    "11743f672f44.1": {
      alpha_stroke: 1,
      sizes: [10, 100],
      spans: [1, 20],
      x: {},
      y: {},
      color: {},
      type: "scatter",
      mode: "line",
      visible: false,
      inherit: true,
    },
  },
  layout: {
    margin: { b: 40, l: 60, t: 25, r: 10 },
    xaxis: {
      domain: [0, 1],
      automargin: true,
      title: "Semana epidemiológica",
    },
    yaxis: { domain: [0, 1], automargin: true, title: "Total" },
    legend: { title: { text: "<b> Año </b>" } },
    hovermode: "compare",
    updatemenus: [
      {
        active: 0,
        buttons: [
          {
            label: "Total",
            method: "update",
            args: [
              { visible: [true, true, true, false, false, false] },
              { yaxis: { title: "Total" } },
            ],
          },
          {
            label: "Tasa",
            method: "update",
            args: [
              { visible: [false, false, false, true, true, true] },
              { yaxis: { title: "Tasa cada 100.000 hab." } },
            ],
          },
        ],
      },
    ],
    showlegend: true,
  },
  source: "A",
  config: { showSendToCloud: false, locale: "es" },
  data: [
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        554,
        602,
        582,
        553,
        579,
        553,
        559,
        594,
        586,
        568,
        605,
        611,
        587,
        607,
        555,
        585,
        612,
        575,
        606,
        558,
        691,
        678,
        763,
        826,
        834,
        794,
        845,
        756,
        727,
        771,
        724,
        660,
        708,
        695,
        653,
        687,
        682,
        636,
        631,
        609,
        618,
        604,
        593,
        600,
        582,
        589,
        539,
        570,
        588,
        630,
        534,
        620,
      ],
      type: "scatter",
      mode: "line",
      name: "2017",
      marker: {
        color: "rgba(102,194,165,1)",
        line: { color: "rgba(102,194,165,1)" },
      },
      textfont: { color: "rgba(102,194,165,1)" },
      error_y: { color: "rgba(102,194,165,1)" },
      error_x: { color: "rgba(102,194,165,1)" },
      line: { color: "rgba(102,194,165,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        616,
        606,
        529,
        571,
        578,
        593,
        535,
        552,
        541,
        557,
        571,
        588,
        542,
        545,
        597,
        607,
        530,
        578,
        602,
        581,
        693,
        665,
        718,
        729,
        779,
        790,
        772,
        805,
        747,
        765,
        789,
        852,
        853,
        786,
        858,
        785,
        790,
        741,
        746,
        662,
        713,
        677,
        602,
        603,
        628,
        627,
        624,
        585,
        535,
        565,
        556,
        602,
      ],
      type: "scatter",
      mode: "line",
      name: "2018",
      marker: {
        color: "rgba(252,141,98,1)",
        line: { color: "rgba(252,141,98,1)" },
      },
      textfont: { color: "rgba(252,141,98,1)" },
      error_y: { color: "rgba(252,141,98,1)" },
      error_x: { color: "rgba(252,141,98,1)" },
      line: { color: "rgba(252,141,98,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        612,
        625,
        533,
        615,
        670,
        592,
        572,
        602,
        546,
        601,
        603,
        578,
        628,
        611,
        611,
        602,
        552,
        636,
        655,
        651,
        650,
        679,
        717,
        734,
        654,
        701,
        764,
        918,
        836,
        825,
        816,
        828,
        800,
        772,
        786,
        753,
        777,
        747,
        721,
        656,
        696,
        641,
        655,
        673,
        596,
        637,
        623,
        606,
        575,
        599,
        582,
        614,
      ],
      type: "scatter",
      mode: "line",
      name: "2019",
      marker: {
        color: "rgba(141,160,203,1)",
        line: { color: "rgba(141,160,203,1)" },
      },
      textfont: { color: "rgba(141,160,203,1)" },
      error_y: { color: "rgba(141,160,203,1)" },
      error_x: { color: "rgba(141,160,203,1)" },
      line: { color: "rgba(141,160,203,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        15.86,
        17.23,
        16.66,
        15.83,
        16.58,
        15.83,
        16,
        17,
        16.78,
        16.26,
        17.32,
        17.49,
        16.8,
        17.38,
        15.89,
        16.75,
        17.52,
        16.46,
        17.35,
        15.97,
        19.78,
        19.41,
        21.84,
        23.65,
        23.87,
        22.73,
        24.19,
        21.64,
        20.81,
        22.07,
        20.73,
        18.89,
        20.27,
        19.9,
        18.69,
        19.67,
        19.52,
        18.21,
        18.06,
        17.43,
        17.69,
        17.29,
        16.98,
        17.18,
        16.66,
        16.86,
        15.43,
        16.32,
        16.83,
        18.04,
        15.29,
        17.75,
      ],
      type: "scatter",
      mode: "line",
      visible: false,
      name: "2017",
      marker: {
        color: "rgba(102,194,165,1)",
        line: { color: "rgba(102,194,165,1)" },
      },
      textfont: { color: "rgba(102,194,165,1)" },
      error_y: { color: "rgba(102,194,165,1)" },
      error_x: { color: "rgba(102,194,165,1)" },
      line: { color: "rgba(102,194,165,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        17.57,
        17.28,
        15.09,
        16.29,
        16.49,
        16.91,
        15.26,
        15.74,
        15.43,
        15.89,
        16.29,
        16.77,
        15.46,
        15.54,
        17.03,
        17.31,
        15.12,
        16.49,
        17.17,
        16.57,
        19.77,
        18.97,
        20.48,
        20.79,
        22.22,
        22.53,
        22.02,
        22.96,
        21.31,
        21.82,
        22.5,
        24.3,
        24.33,
        22.42,
        24.47,
        22.39,
        22.53,
        21.14,
        21.28,
        18.88,
        20.34,
        19.31,
        17.17,
        17.2,
        17.91,
        17.88,
        17.8,
        16.69,
        15.26,
        16.12,
        15.86,
        17.17,
      ],
      type: "scatter",
      mode: "line",
      visible: false,
      name: "2018",
      marker: {
        color: "rgba(252,141,98,1)",
        line: { color: "rgba(252,141,98,1)" },
      },
      textfont: { color: "rgba(252,141,98,1)" },
      error_y: { color: "rgba(252,141,98,1)" },
      error_x: { color: "rgba(252,141,98,1)" },
      line: { color: "rgba(252,141,98,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        17.39,
        17.76,
        15.15,
        17.48,
        19.04,
        16.83,
        16.26,
        17.11,
        15.52,
        17.08,
        17.14,
        16.43,
        17.85,
        17.37,
        17.37,
        17.11,
        15.69,
        18.08,
        18.62,
        18.5,
        18.47,
        19.3,
        20.38,
        20.86,
        18.59,
        19.92,
        21.71,
        26.09,
        23.76,
        23.45,
        23.19,
        23.53,
        22.74,
        21.94,
        22.34,
        21.4,
        22.08,
        21.23,
        20.49,
        18.64,
        19.78,
        18.22,
        18.62,
        19.13,
        16.94,
        18.1,
        17.71,
        17.22,
        16.34,
        17.02,
        16.54,
        17.45,
      ],
      type: "scatter",
      mode: "line",
      visible: false,
      name: "2019",
      marker: {
        color: "rgba(141,160,203,1)",
        line: { color: "rgba(141,160,203,1)" },
      },
      textfont: { color: "rgba(141,160,203,1)" },
      error_y: { color: "rgba(141,160,203,1)" },
      error_x: { color: "rgba(141,160,203,1)" },
      line: { color: "rgba(141,160,203,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
  ],
  highlight: {
    on: "plotly_click",
    persistent: false,
    dynamic: false,
    selectize: false,
    opacityDim: 0.2,
    selected: { opacity: 1 },
    debounce: 0,
  },
  shinyEvents: [
    "plotly_hover",
    "plotly_click",
    "plotly_selected",
    "plotly_relayout",
    "plotly_brushed",
    "plotly_brushing",
    "plotly_clickannotation",
    "plotly_doubleclick",
    "plotly_deselect",
    "plotly_afterplot",
    "plotly_sunburstclick",
  ],
  base_url: "https://plot.ly",
};
