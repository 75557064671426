export default {

  visdat: { "11744fc556a9": ["function () ", "plotlyVisDat"] },
  cur_data: "11744fc556a9",
  attrs: {
    "11744fc556a9": {
      alpha_stroke: 1,
      sizes: [10, 100],
      spans: [1, 20],
      x: {},
      y: {},
      color: {},
      linetype: {},
      type: "scatter",
      mode: "line",
      inherit: true,
    },
    "11744fc556a9.1": {
      alpha_stroke: 1,
      sizes: [10, 100],
      spans: [1, 20],
      x: {},
      y: {},
      color: {},
      linetype: {},
      type: "scatter",
      mode: "line",
      visible: false,
      inherit: true,
    },
  },
  layout: {
    margin: { b: 40, l: 60, t: 25, r: 10 },
    xaxis: {
      domain: [0, 1],
      automargin: true,
      title: "Semana epidemiológica",
    },
    yaxis: { domain: [0, 1], automargin: true, title: "Total" },
    legend: { title: { text: "<b> Año/Región </b>" } },
    hovermode: "compare",
    updatemenus: [
      {
        active: 0,
        buttons: [
          {
            label: "Total",
            method: "update",
            args: [
              {
                visible: [
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                ],
              },
              { yaxis: { title: "Total" } },
            ],
          },
          {
            label: "Tasa",
            method: "update",
            args: [
              {
                visible: [
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                ],
              },
              { yaxis: { title: "Tasa cada 100.000 hab." } },
            ],
          },
        ],
      },
    ],
    showlegend: true,
  },
  source: "A",
  config: { showSendToCloud: false, locale: "es" },
  data: [
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        82,
        81,
        66,
        76,
        83,
        67,
        65,
        88,
        65,
        75,
        68,
        67,
        74,
        81,
        64,
        80,
        78,
        67,
        73,
        72,
        89,
        87,
        90,
        81,
        90,
        78,
        118,
        91,
        86,
        99,
        93,
        80,
        88,
        89,
        83,
        83,
        80,
        82,
        81,
        79,
        74,
        62,
        81,
        70,
        70,
        65,
        61,
        72,
        60,
        101,
        85,
        91,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "2017<br />Este",
      line: { color: "rgba(102,194,165,1)", dash: "solid" },
      marker: {
        color: "rgba(102,194,165,1)",
        line: { color: "rgba(102,194,165,1)" },
      },
      textfont: { color: "rgba(102,194,165,1)" },
      error_y: { color: "rgba(102,194,165,1)" },
      error_x: { color: "rgba(102,194,165,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        68,
        75,
        80,
        93,
        77,
        67,
        74,
        65,
        82,
        73,
        81,
        81,
        77,
        80,
        80,
        85,
        78,
        66,
        90,
        78,
        101,
        95,
        95,
        113,
        131,
        95,
        117,
        105,
        113,
        108,
        105,
        91,
        93,
        93,
        103,
        105,
        98,
        83,
        91,
        68,
        85,
        85,
        79,
        95,
        100,
        75,
        58,
        82,
        67,
        95,
        91,
        74,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "2017<br />Norte",
      line: { color: "rgba(102,194,165,1)", dash: "dash" },
      marker: {
        color: "rgba(102,194,165,1)",
        line: { color: "rgba(102,194,165,1)" },
      },
      textfont: { color: "rgba(102,194,165,1)" },
      error_y: { color: "rgba(102,194,165,1)" },
      error_x: { color: "rgba(102,194,165,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        76,
        94,
        85,
        58,
        91,
        92,
        70,
        84,
        71,
        79,
        97,
        91,
        76,
        97,
        90,
        83,
        83,
        83,
        80,
        81,
        105,
        85,
        118,
        122,
        125,
        94,
        126,
        104,
        120,
        113,
        116,
        95,
        113,
        103,
        82,
        100,
        87,
        81,
        89,
        97,
        85,
        95,
        88,
        74,
        67,
        102,
        76,
        87,
        92,
        74,
        59,
        80,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "2017<br />Oeste",
      line: { color: "rgba(102,194,165,1)", dash: "dot" },
      marker: {
        color: "rgba(102,194,165,1)",
        line: { color: "rgba(102,194,165,1)" },
      },
      textfont: { color: "rgba(102,194,165,1)" },
      error_y: { color: "rgba(102,194,165,1)" },
      error_x: { color: "rgba(102,194,165,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        328,
        352,
        351,
        326,
        328,
        327,
        350,
        356,
        368,
        341,
        359,
        371,
        359,
        349,
        321,
        337,
        373,
        359,
        363,
        327,
        396,
        411,
        460,
        510,
        488,
        526,
        484,
        456,
        408,
        451,
        410,
        394,
        413,
        403,
        378,
        393,
        411,
        384,
        366,
        362,
        372,
        359,
        340,
        360,
        341,
        345,
        339,
        328,
        361,
        353,
        288,
        363,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "2017<br />Sur",
      line: { color: "rgba(102,194,165,1)", dash: "dashdot" },
      marker: {
        color: "rgba(102,194,165,1)",
        line: { color: "rgba(102,194,165,1)" },
      },
      textfont: { color: "rgba(102,194,165,1)" },
      error_y: { color: "rgba(102,194,165,1)" },
      error_x: { color: "rgba(102,194,165,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        80,
        90,
        73,
        84,
        89,
        82,
        93,
        75,
        58,
        72,
        78,
        82,
        61,
        81,
        69,
        89,
        77,
        75,
        93,
        54,
        87,
        76,
        82,
        87,
        93,
        97,
        100,
        100,
        94,
        97,
        109,
        102,
        115,
        98,
        98,
        119,
        84,
        104,
        94,
        84,
        98,
        93,
        67,
        80,
        95,
        71,
        90,
        71,
        70,
        71,
        73,
        60,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "2018<br />Este",
      line: { color: "rgba(252,141,98,1)", dash: "solid" },
      marker: {
        color: "rgba(252,141,98,1)",
        line: { color: "rgba(252,141,98,1)" },
      },
      textfont: { color: "rgba(252,141,98,1)" },
      error_y: { color: "rgba(252,141,98,1)" },
      error_x: { color: "rgba(252,141,98,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        81,
        85,
        81,
        77,
        64,
        80,
        77,
        79,
        71,
        74,
        77,
        81,
        71,
        90,
        103,
        70,
        84,
        79,
        75,
        100,
        91,
        94,
        103,
        120,
        131,
        111,
        118,
        135,
        115,
        124,
        123,
        129,
        129,
        115,
        114,
        115,
        103,
        112,
        119,
        84,
        74,
        102,
        73,
        84,
        94,
        85,
        83,
        95,
        76,
        87,
        74,
        99,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "2018<br />Norte",
      line: { color: "rgba(252,141,98,1)", dash: "dash" },
      marker: {
        color: "rgba(252,141,98,1)",
        line: { color: "rgba(252,141,98,1)" },
      },
      textfont: { color: "rgba(252,141,98,1)" },
      error_y: { color: "rgba(252,141,98,1)" },
      error_x: { color: "rgba(252,141,98,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        89,
        87,
        65,
        82,
        97,
        91,
        75,
        75,
        85,
        82,
        84,
        85,
        101,
        60,
        77,
        98,
        60,
        78,
        88,
        84,
        103,
        88,
        114,
        119,
        111,
        115,
        101,
        106,
        105,
        112,
        107,
        131,
        133,
        107,
        126,
        115,
        119,
        103,
        98,
        83,
        110,
        86,
        89,
        79,
        82,
        103,
        84,
        85,
        86,
        81,
        78,
        98,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "2018<br />Oeste",
      line: { color: "rgba(252,141,98,1)", dash: "dot" },
      marker: {
        color: "rgba(252,141,98,1)",
        line: { color: "rgba(252,141,98,1)" },
      },
      textfont: { color: "rgba(252,141,98,1)" },
      error_y: { color: "rgba(252,141,98,1)" },
      error_x: { color: "rgba(252,141,98,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        365,
        344,
        310,
        328,
        328,
        340,
        290,
        323,
        327,
        329,
        332,
        340,
        309,
        314,
        348,
        350,
        309,
        346,
        346,
        343,
        412,
        407,
        419,
        403,
        444,
        467,
        453,
        464,
        433,
        432,
        450,
        490,
        476,
        466,
        520,
        435,
        484,
        422,
        435,
        411,
        431,
        396,
        373,
        360,
        357,
        368,
        367,
        334,
        303,
        326,
        331,
        345,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "2018<br />Sur",
      line: { color: "rgba(252,141,98,1)", dash: "dashdot" },
      marker: {
        color: "rgba(252,141,98,1)",
        line: { color: "rgba(252,141,98,1)" },
      },
      textfont: { color: "rgba(252,141,98,1)" },
      error_y: { color: "rgba(252,141,98,1)" },
      error_x: { color: "rgba(252,141,98,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        78,
        76,
        80,
        83,
        91,
        75,
        78,
        87,
        71,
        79,
        78,
        68,
        71,
        76,
        73,
        74,
        69,
        82,
        90,
        93,
        74,
        93,
        90,
        111,
        85,
        75,
        102,
        109,
        107,
        92,
        93,
        90,
        119,
        94,
        113,
        104,
        110,
        90,
        96,
        90,
        83,
        80,
        88,
        100,
        59,
        94,
        76,
        75,
        80,
        74,
        69,
        82,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "2019<br />Este",
      line: { color: "rgba(141,160,203,1)", dash: "solid" },
      marker: {
        color: "rgba(141,160,203,1)",
        line: { color: "rgba(141,160,203,1)" },
      },
      textfont: { color: "rgba(141,160,203,1)" },
      error_y: { color: "rgba(141,160,203,1)" },
      error_x: { color: "rgba(141,160,203,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        97,
        90,
        59,
        75,
        77,
        86,
        84,
        74,
        78,
        81,
        73,
        91,
        90,
        97,
        92,
        98,
        84,
        83,
        84,
        93,
        82,
        81,
        112,
        108,
        109,
        89,
        101,
        126,
        125,
        128,
        120,
        115,
        101,
        113,
        102,
        101,
        118,
        108,
        100,
        74,
        96,
        78,
        97,
        88,
        99,
        88,
        100,
        83,
        71,
        85,
        79,
        75,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "2019<br />Norte",
      line: { color: "rgba(141,160,203,1)", dash: "dash" },
      marker: {
        color: "rgba(141,160,203,1)",
        line: { color: "rgba(141,160,203,1)" },
      },
      textfont: { color: "rgba(141,160,203,1)" },
      error_y: { color: "rgba(141,160,203,1)" },
      error_x: { color: "rgba(141,160,203,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        90,
        79,
        77,
        82,
        90,
        85,
        78,
        70,
        74,
        76,
        78,
        81,
        84,
        101,
        101,
        72,
        81,
        95,
        95,
        111,
        105,
        112,
        94,
        117,
        88,
        96,
        121,
        135,
        119,
        121,
        128,
        119,
        106,
        120,
        116,
        100,
        100,
        106,
        105,
        86,
        90,
        75,
        98,
        107,
        81,
        95,
        104,
        74,
        95,
        99,
        71,
        104,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "2019<br />Oeste",
      line: { color: "rgba(141,160,203,1)", dash: "dot" },
      marker: {
        color: "rgba(141,160,203,1)",
        line: { color: "rgba(141,160,203,1)" },
      },
      textfont: { color: "rgba(141,160,203,1)" },
      error_y: { color: "rgba(141,160,203,1)" },
      error_x: { color: "rgba(141,160,203,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        347,
        380,
        317,
        375,
        412,
        346,
        332,
        371,
        323,
        365,
        374,
        338,
        383,
        337,
        345,
        358,
        318,
        376,
        386,
        354,
        389,
        393,
        420,
        398,
        372,
        441,
        440,
        548,
        485,
        484,
        475,
        504,
        474,
        445,
        455,
        448,
        449,
        443,
        420,
        406,
        427,
        408,
        372,
        378,
        357,
        360,
        343,
        374,
        329,
        341,
        363,
        353,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "2019<br />Sur",
      line: { color: "rgba(141,160,203,1)", dash: "dashdot" },
      marker: {
        color: "rgba(141,160,203,1)",
        line: { color: "rgba(141,160,203,1)" },
      },
      textfont: { color: "rgba(141,160,203,1)" },
      error_y: { color: "rgba(141,160,203,1)" },
      error_x: { color: "rgba(141,160,203,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        17.79,
        17.58,
        14.32,
        16.49,
        18.01,
        14.54,
        14.11,
        19.1,
        14.11,
        16.28,
        14.76,
        14.54,
        16.06,
        17.58,
        13.89,
        17.36,
        16.93,
        14.54,
        15.84,
        15.62,
        19.31,
        18.88,
        19.53,
        17.58,
        19.53,
        16.93,
        25.61,
        19.75,
        18.66,
        21.48,
        20.18,
        17.36,
        19.1,
        19.31,
        18.01,
        18.01,
        17.36,
        17.79,
        17.58,
        17.14,
        16.06,
        13.45,
        17.58,
        15.19,
        15.19,
        14.11,
        13.24,
        15.62,
        13.02,
        21.92,
        18.45,
        19.75,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "2017<br />Este",
      line: { color: "rgba(102,194,165,1)", dash: "solid" },
      marker: {
        color: "rgba(102,194,165,1)",
        line: { color: "rgba(102,194,165,1)" },
      },
      textfont: { color: "rgba(102,194,165,1)" },
      error_y: { color: "rgba(102,194,165,1)" },
      error_x: { color: "rgba(102,194,165,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        12.89,
        14.22,
        15.16,
        17.63,
        14.6,
        12.7,
        14.03,
        12.32,
        15.54,
        13.84,
        15.35,
        15.35,
        14.6,
        15.16,
        15.16,
        16.11,
        14.79,
        12.51,
        17.06,
        14.79,
        19.14,
        18.01,
        18.01,
        21.42,
        24.83,
        18.01,
        22.18,
        19.9,
        21.42,
        20.47,
        19.9,
        17.25,
        17.63,
        17.63,
        19.52,
        19.9,
        18.58,
        15.73,
        17.25,
        12.89,
        16.11,
        16.11,
        14.97,
        18.01,
        18.96,
        14.22,
        10.99,
        15.54,
        12.7,
        18.01,
        17.25,
        14.03,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "2017<br />Norte",
      line: { color: "rgba(102,194,165,1)", dash: "dash" },
      marker: {
        color: "rgba(102,194,165,1)",
        line: { color: "rgba(102,194,165,1)" },
      },
      textfont: { color: "rgba(102,194,165,1)" },
      error_y: { color: "rgba(102,194,165,1)" },
      error_x: { color: "rgba(102,194,165,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        14.02,
        17.34,
        15.68,
        10.7,
        16.79,
        16.97,
        12.91,
        15.5,
        13.1,
        14.57,
        17.89,
        16.79,
        14.02,
        17.89,
        16.6,
        15.31,
        15.31,
        15.31,
        14.76,
        14.94,
        19.37,
        15.68,
        21.77,
        22.51,
        23.06,
        17.34,
        23.24,
        19.19,
        22.14,
        20.85,
        21.4,
        17.53,
        20.85,
        19,
        15.13,
        18.45,
        16.05,
        14.94,
        16.42,
        17.89,
        15.68,
        17.53,
        16.23,
        13.65,
        12.36,
        18.82,
        14.02,
        16.05,
        16.97,
        13.65,
        10.88,
        14.76,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "2017<br />Oeste",
      line: { color: "rgba(102,194,165,1)", dash: "dot" },
      marker: {
        color: "rgba(102,194,165,1)",
        line: { color: "rgba(102,194,165,1)" },
      },
      textfont: { color: "rgba(102,194,165,1)" },
      error_y: { color: "rgba(102,194,165,1)" },
      error_x: { color: "rgba(102,194,165,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        16.71,
        17.93,
        17.88,
        16.61,
        16.71,
        16.66,
        17.83,
        18.14,
        18.75,
        17.37,
        18.29,
        18.9,
        18.29,
        17.78,
        16.35,
        17.17,
        19,
        18.29,
        18.49,
        16.66,
        20.18,
        20.94,
        23.44,
        25.98,
        24.86,
        26.8,
        24.66,
        23.23,
        20.79,
        22.98,
        20.89,
        20.07,
        21.04,
        20.53,
        19.26,
        20.02,
        20.94,
        19.56,
        18.65,
        18.44,
        18.95,
        18.29,
        17.32,
        18.34,
        17.37,
        17.58,
        17.27,
        16.71,
        18.39,
        17.98,
        14.67,
        18.49,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "2017<br />Sur",
      line: { color: "rgba(102,194,165,1)", dash: "dashdot" },
      marker: {
        color: "rgba(102,194,165,1)",
        line: { color: "rgba(102,194,165,1)" },
      },
      textfont: { color: "rgba(102,194,165,1)" },
      error_y: { color: "rgba(102,194,165,1)" },
      error_x: { color: "rgba(102,194,165,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        17.27,
        19.43,
        15.76,
        18.13,
        19.21,
        17.7,
        20.08,
        16.19,
        12.52,
        15.54,
        16.84,
        17.7,
        13.17,
        17.49,
        14.89,
        19.21,
        16.62,
        16.19,
        20.08,
        11.66,
        18.78,
        16.41,
        17.7,
        18.78,
        20.08,
        20.94,
        21.59,
        21.59,
        20.29,
        20.94,
        23.53,
        22.02,
        24.82,
        21.15,
        21.15,
        25.69,
        18.13,
        22.45,
        20.29,
        18.13,
        21.15,
        20.08,
        14.46,
        17.27,
        20.51,
        15.33,
        19.43,
        15.33,
        15.11,
        15.33,
        15.76,
        12.95,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "2018<br />Este",
      line: { color: "rgba(252,141,98,1)", dash: "solid" },
      marker: {
        color: "rgba(252,141,98,1)",
        line: { color: "rgba(252,141,98,1)" },
      },
      textfont: { color: "rgba(252,141,98,1)" },
      error_y: { color: "rgba(252,141,98,1)" },
      error_x: { color: "rgba(252,141,98,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        15.33,
        16.09,
        15.33,
        14.58,
        12.11,
        15.14,
        14.58,
        14.95,
        13.44,
        14.01,
        14.58,
        15.33,
        13.44,
        17.04,
        19.5,
        13.25,
        15.9,
        14.95,
        14.2,
        18.93,
        17.23,
        17.79,
        19.5,
        22.71,
        24.8,
        21.01,
        22.34,
        25.55,
        21.77,
        23.47,
        23.28,
        24.42,
        24.42,
        21.77,
        21.58,
        21.77,
        19.5,
        21.2,
        22.53,
        15.9,
        14.01,
        19.31,
        13.82,
        15.9,
        17.79,
        16.09,
        15.71,
        17.98,
        14.39,
        16.47,
        14.01,
        18.74,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "2018<br />Norte",
      line: { color: "rgba(252,141,98,1)", dash: "dash" },
      marker: {
        color: "rgba(252,141,98,1)",
        line: { color: "rgba(252,141,98,1)" },
      },
      textfont: { color: "rgba(252,141,98,1)" },
      error_y: { color: "rgba(252,141,98,1)" },
      error_x: { color: "rgba(252,141,98,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        16.37,
        16.01,
        11.96,
        15.09,
        17.85,
        16.74,
        13.8,
        13.8,
        15.64,
        15.09,
        15.45,
        15.64,
        18.58,
        11.04,
        14.17,
        18.03,
        11.04,
        14.35,
        16.19,
        15.45,
        18.95,
        16.19,
        20.97,
        21.89,
        20.42,
        21.16,
        18.58,
        19.5,
        19.32,
        20.61,
        19.69,
        24.1,
        24.47,
        19.69,
        23.18,
        21.16,
        21.89,
        18.95,
        18.03,
        15.27,
        20.24,
        15.82,
        16.37,
        14.53,
        15.09,
        18.95,
        15.45,
        15.64,
        15.82,
        14.9,
        14.35,
        18.03,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "2018<br />Oeste",
      line: { color: "rgba(252,141,98,1)", dash: "dot" },
      marker: {
        color: "rgba(252,141,98,1)",
        line: { color: "rgba(252,141,98,1)" },
      },
      textfont: { color: "rgba(252,141,98,1)" },
      error_y: { color: "rgba(252,141,98,1)" },
      error_x: { color: "rgba(252,141,98,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        18.52,
        17.45,
        15.73,
        16.64,
        16.64,
        17.25,
        14.71,
        16.39,
        16.59,
        16.69,
        16.85,
        17.25,
        15.68,
        15.93,
        17.66,
        17.76,
        15.68,
        17.56,
        17.56,
        17.4,
        20.9,
        20.65,
        21.26,
        20.45,
        22.53,
        23.69,
        22.98,
        23.54,
        21.97,
        21.92,
        22.83,
        24.86,
        24.15,
        23.64,
        26.38,
        22.07,
        24.56,
        21.41,
        22.07,
        20.85,
        21.87,
        20.09,
        18.93,
        18.27,
        18.11,
        18.67,
        18.62,
        16.95,
        15.37,
        16.54,
        16.79,
        17.5,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "2018<br />Sur",
      line: { color: "rgba(252,141,98,1)", dash: "dashdot" },
      marker: {
        color: "rgba(252,141,98,1)",
        line: { color: "rgba(252,141,98,1)" },
      },
      textfont: { color: "rgba(252,141,98,1)" },
      error_y: { color: "rgba(252,141,98,1)" },
      error_x: { color: "rgba(252,141,98,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        16.75,
        16.32,
        17.18,
        17.82,
        19.54,
        16.11,
        16.75,
        18.68,
        15.25,
        16.96,
        16.75,
        14.6,
        15.25,
        16.32,
        15.68,
        15.89,
        14.82,
        17.61,
        19.33,
        19.97,
        15.89,
        19.97,
        19.33,
        23.84,
        18.25,
        16.11,
        21.9,
        23.41,
        22.98,
        19.76,
        19.97,
        19.33,
        25.55,
        20.19,
        24.27,
        22.33,
        23.62,
        19.33,
        20.62,
        19.33,
        17.82,
        17.18,
        18.9,
        21.47,
        12.67,
        20.19,
        16.32,
        16.11,
        17.18,
        15.89,
        14.82,
        17.61,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "2019<br />Este",
      line: { color: "rgba(141,160,203,1)", dash: "solid" },
      marker: {
        color: "rgba(141,160,203,1)",
        line: { color: "rgba(141,160,203,1)" },
      },
      textfont: { color: "rgba(141,160,203,1)" },
      error_y: { color: "rgba(141,160,203,1)" },
      error_x: { color: "rgba(141,160,203,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        18.34,
        17.01,
        11.15,
        14.18,
        14.56,
        16.26,
        15.88,
        13.99,
        14.75,
        15.31,
        13.8,
        17.2,
        17.01,
        18.34,
        17.39,
        18.53,
        15.88,
        15.69,
        15.88,
        17.58,
        15.5,
        15.31,
        21.17,
        20.42,
        20.61,
        16.83,
        19.09,
        23.82,
        23.63,
        24.2,
        22.69,
        21.74,
        19.09,
        21.36,
        19.28,
        19.09,
        22.31,
        20.42,
        18.9,
        13.99,
        18.15,
        14.75,
        18.34,
        16.64,
        18.72,
        16.64,
        18.9,
        15.69,
        13.42,
        16.07,
        14.93,
        14.18,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "2019<br />Norte",
      line: { color: "rgba(141,160,203,1)", dash: "dash" },
      marker: {
        color: "rgba(141,160,203,1)",
        line: { color: "rgba(141,160,203,1)" },
      },
      textfont: { color: "rgba(141,160,203,1)" },
      error_y: { color: "rgba(141,160,203,1)" },
      error_x: { color: "rgba(141,160,203,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        16.51,
        14.5,
        14.13,
        15.05,
        16.51,
        15.6,
        14.31,
        12.84,
        13.58,
        13.95,
        14.31,
        14.86,
        15.41,
        18.53,
        18.53,
        13.21,
        14.86,
        17.43,
        17.43,
        20.37,
        19.27,
        20.55,
        17.25,
        21.47,
        16.15,
        17.62,
        22.2,
        24.77,
        21.84,
        22.2,
        23.49,
        21.84,
        19.45,
        22.02,
        21.29,
        18.35,
        18.35,
        19.45,
        19.27,
        15.78,
        16.51,
        13.76,
        17.98,
        19.63,
        14.86,
        17.43,
        19.08,
        13.58,
        17.43,
        18.17,
        13.03,
        19.08,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "2019<br />Oeste",
      line: { color: "rgba(141,160,203,1)", dash: "dot" },
      marker: {
        color: "rgba(141,160,203,1)",
        line: { color: "rgba(141,160,203,1)" },
      },
      textfont: { color: "rgba(141,160,203,1)" },
      error_y: { color: "rgba(141,160,203,1)" },
      error_x: { color: "rgba(141,160,203,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        17.53,
        19.2,
        16.02,
        18.95,
        20.82,
        17.48,
        16.78,
        18.75,
        16.32,
        18.44,
        18.9,
        17.08,
        19.35,
        17.03,
        17.43,
        18.09,
        16.07,
        19,
        19.51,
        17.89,
        19.66,
        19.86,
        21.22,
        20.11,
        18.8,
        22.28,
        22.23,
        27.69,
        24.51,
        24.46,
        24,
        25.47,
        23.95,
        22.49,
        22.99,
        22.64,
        22.69,
        22.39,
        21.22,
        20.52,
        21.58,
        20.62,
        18.8,
        19.1,
        18.04,
        18.19,
        17.33,
        18.9,
        16.63,
        17.23,
        18.34,
        17.84,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "2019<br />Sur",
      line: { color: "rgba(141,160,203,1)", dash: "dashdot" },
      marker: {
        color: "rgba(141,160,203,1)",
        line: { color: "rgba(141,160,203,1)" },
      },
      textfont: { color: "rgba(141,160,203,1)" },
      error_y: { color: "rgba(141,160,203,1)" },
      error_x: { color: "rgba(141,160,203,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
  ],
  highlight: {
    on: "plotly_click",
    persistent: false,
    dynamic: false,
    selectize: false,
    opacityDim: 0.2,
    selected: { opacity: 1 },
    debounce: 0,
  },
  shinyEvents: [
    "plotly_hover",
    "plotly_click",
    "plotly_selected",
    "plotly_relayout",
    "plotly_brushed",
    "plotly_brushing",
    "plotly_clickannotation",
    "plotly_doubleclick",
    "plotly_deselect",
    "plotly_afterplot",
    "plotly_sunburstclick",
  ],
  base_url: "https://plot.ly",
}
