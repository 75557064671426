export default {
  visdat: { "1174d086e57": ["function () ", "plotlyVisDat"] },
  cur_data: "1174d086e57",
  attrs: {
    "1174d086e57": {
      alpha_stroke: 1,
      sizes: [10, 100],
      spans: [1, 20],
      x: {},
      y: {},
      color: {},
      linetype: {},
      type: "scatter",
      mode: "line",
      inherit: true,
    },
    "1174d086e57.1": {
      alpha_stroke: 1,
      sizes: [10, 100],
      spans: [1, 20],
      x: {},
      y: {},
      color: {},
      linetype: {},
      type: "scatter",
      mode: "line",
      visible: false,
      inherit: true,
    },
  },
  layout: {
    margin: { b: 40, l: 60, t: 25, r: 10 },
    xaxis: {
      domain: [0, 1],
      automargin: true,
      title: "Semana epidemiológica",
    },
    yaxis: { domain: [0, 1], automargin: true, title: "Total" },
    legend: { title: { text: "<b> Tramo etario/Año </b>" } },
    hovermode: "compare",
    updatemenus: [
      {
        active: 0,
        buttons: [
          {
            label: "Total",
            method: "update",
            args: [
              {
                visible: [
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                ],
              },
              { yaxis: { title: "Total" } },
            ],
          },
          {
            label: "Tasa",
            method: "update",
            args: [
              {
                visible: [
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                ],
              },
              { yaxis: { title: "Tasa cada 100.000 hab." } },
            ],
          },
        ],
      },
    ],
    showlegend: true,
  },
  source: "A",
  config: { showSendToCloud: false, locale: "es" },
  data: [
    {
      x: [
        1,
        3,
        4,
        5,
        6,
        7,
        9,
        11,
        13,
        17,
        18,
        20,
        22,
        23,
        25,
        26,
        28,
        30,
        31,
        32,
        33,
        35,
        36,
        38,
        39,
        41,
        42,
        43,
        44,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        2,
        2,
        1,
        1,
        1,
        3,
        1,
        1,
        1,
        1,
        2,
        1,
        1,
        1,
        1,
        3,
        2,
        2,
        2,
        3,
        2,
        2,
        1,
        1,
        3,
        2,
        1,
        3,
        1,
        2,
        2,
        2,
        1,
        1,
        2,
        1,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "1 a 4<br />2017",
      line: { color: "rgba(252,141,98,1)", dash: "solid" },
      marker: {
        color: "rgba(252,141,98,1)",
        line: { color: "rgba(252,141,98,1)" },
      },
      textfont: { color: "rgba(252,141,98,1)" },
      error_y: { color: "rgba(252,141,98,1)" },
      error_x: { color: "rgba(252,141,98,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        7,
        9,
        12,
        15,
        16,
        17,
        18,
        20,
        23,
        24,
        25,
        26,
        28,
        30,
        31,
        33,
        35,
        36,
        37,
        38,
        39,
        41,
        42,
        43,
        44,
        45,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        3,
        2,
        1,
        1,
        1,
        2,
        2,
        1,
        3,
        1,
        1,
        1,
        2,
        1,
        2,
        1,
        4,
        4,
        1,
        3,
        3,
        2,
        2,
        1,
        1,
        1,
        3,
        2,
        1,
        2,
        4,
        1,
        1,
        1,
        1,
        1,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "1 a 4<br />2018",
      line: { color: "rgba(252,141,98,1)", dash: "dash" },
      marker: {
        color: "rgba(252,141,98,1)",
        line: { color: "rgba(252,141,98,1)" },
      },
      textfont: { color: "rgba(252,141,98,1)" },
      error_y: { color: "rgba(252,141,98,1)" },
      error_x: { color: "rgba(252,141,98,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        2,
        4,
        6,
        7,
        8,
        13,
        14,
        15,
        16,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        37,
        38,
        39,
        42,
        43,
        49,
        51,
      ],
      y: [
        1,
        2,
        2,
        1,
        1,
        1,
        2,
        1,
        3,
        2,
        3,
        1,
        1,
        2,
        3,
        2,
        1,
        1,
        1,
        1,
        3,
        1,
        1,
        1,
        4,
        1,
        2,
        3,
        3,
        1,
        2,
        4,
        3,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "1 a 4<br />2019",
      line: { color: "rgba(252,141,98,1)", dash: "dot" },
      marker: {
        color: "rgba(252,141,98,1)",
        line: { color: "rgba(252,141,98,1)" },
      },
      textfont: { color: "rgba(252,141,98,1)" },
      error_y: { color: "rgba(252,141,98,1)" },
      error_x: { color: "rgba(252,141,98,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        12,
        10,
        12,
        13,
        12,
        8,
        5,
        13,
        13,
        6,
        11,
        12,
        21,
        10,
        13,
        9,
        7,
        6,
        10,
        10,
        11,
        6,
        12,
        7,
        3,
        8,
        4,
        8,
        11,
        12,
        14,
        5,
        1,
        9,
        10,
        13,
        12,
        10,
        4,
        8,
        6,
        9,
        8,
        6,
        13,
        10,
        7,
        4,
        11,
        8,
        10,
        11,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "15 a 24<br />2017",
      line: { color: "rgba(231,138,195,1)", dash: "solid" },
      marker: {
        color: "rgba(231,138,195,1)",
        line: { color: "rgba(231,138,195,1)" },
      },
      textfont: { color: "rgba(231,138,195,1)" },
      error_y: { color: "rgba(231,138,195,1)" },
      error_x: { color: "rgba(231,138,195,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        10,
        12,
        16,
        12,
        4,
        12,
        7,
        8,
        7,
        7,
        16,
        10,
        15,
        6,
        6,
        10,
        6,
        11,
        7,
        5,
        13,
        6,
        12,
        7,
        11,
        9,
        13,
        12,
        9,
        6,
        12,
        5,
        12,
        6,
        7,
        10,
        9,
        11,
        16,
        6,
        15,
        8,
        9,
        12,
        11,
        7,
        6,
        9,
        3,
        5,
        14,
        12,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "15 a 24<br />2018",
      line: { color: "rgba(231,138,195,1)", dash: "dash" },
      marker: {
        color: "rgba(231,138,195,1)",
        line: { color: "rgba(231,138,195,1)" },
      },
      textfont: { color: "rgba(231,138,195,1)" },
      error_y: { color: "rgba(231,138,195,1)" },
      error_x: { color: "rgba(231,138,195,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        8,
        12,
        5,
        9,
        7,
        11,
        6,
        11,
        8,
        7,
        11,
        10,
        12,
        11,
        8,
        5,
        8,
        5,
        6,
        6,
        17,
        9,
        10,
        1,
        9,
        4,
        7,
        9,
        15,
        5,
        8,
        11,
        11,
        7,
        11,
        6,
        7,
        15,
        10,
        10,
        6,
        5,
        7,
        5,
        14,
        11,
        16,
        9,
        6,
        8,
        14,
        19,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "15 a 24<br />2019",
      line: { color: "rgba(231,138,195,1)", dash: "dot" },
      marker: {
        color: "rgba(231,138,195,1)",
        line: { color: "rgba(231,138,195,1)" },
      },
      textfont: { color: "rgba(231,138,195,1)" },
      error_y: { color: "rgba(231,138,195,1)" },
      error_x: { color: "rgba(231,138,195,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        23,
        32,
        37,
        42,
        25,
        31,
        25,
        32,
        24,
        34,
        27,
        25,
        16,
        24,
        18,
        21,
        30,
        18,
        24,
        23,
        21,
        28,
        27,
        22,
        21,
        27,
        32,
        27,
        32,
        18,
        30,
        22,
        33,
        30,
        24,
        32,
        17,
        26,
        25,
        27,
        22,
        29,
        22,
        31,
        27,
        27,
        19,
        32,
        20,
        38,
        26,
        38,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "25 a 44<br />2017",
      line: { color: "rgba(166,216,84,1)", dash: "solid" },
      marker: {
        color: "rgba(166,216,84,1)",
        line: { color: "rgba(166,216,84,1)" },
      },
      textfont: { color: "rgba(166,216,84,1)" },
      error_y: { color: "rgba(166,216,84,1)" },
      error_x: { color: "rgba(166,216,84,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        31,
        27,
        20,
        26,
        30,
        39,
        23,
        35,
        29,
        19,
        25,
        26,
        25,
        24,
        25,
        37,
        26,
        30,
        32,
        26,
        31,
        20,
        28,
        24,
        25,
        28,
        21,
        38,
        37,
        24,
        28,
        35,
        28,
        39,
        30,
        23,
        34,
        34,
        28,
        28,
        29,
        22,
        24,
        27,
        33,
        26,
        37,
        30,
        27,
        27,
        26,
        28,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "25 a 44<br />2018",
      line: { color: "rgba(166,216,84,1)", dash: "dash" },
      marker: {
        color: "rgba(166,216,84,1)",
        line: { color: "rgba(166,216,84,1)" },
      },
      textfont: { color: "rgba(166,216,84,1)" },
      error_y: { color: "rgba(166,216,84,1)" },
      error_x: { color: "rgba(166,216,84,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        31,
        36,
        21,
        31,
        42,
        28,
        27,
        23,
        17,
        37,
        18,
        21,
        34,
        33,
        27,
        34,
        28,
        33,
        28,
        24,
        22,
        25,
        25,
        31,
        36,
        31,
        39,
        21,
        26,
        29,
        37,
        28,
        29,
        24,
        28,
        28,
        26,
        32,
        22,
        31,
        25,
        34,
        38,
        28,
        25,
        18,
        17,
        29,
        24,
        29,
        27,
        43,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "25 a 44<br />2019",
      line: { color: "rgba(166,216,84,1)", dash: "dot" },
      marker: {
        color: "rgba(166,216,84,1)",
        line: { color: "rgba(166,216,84,1)" },
      },
      textfont: { color: "rgba(166,216,84,1)" },
      error_y: { color: "rgba(166,216,84,1)" },
      error_x: { color: "rgba(166,216,84,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        72,
        89,
        103,
        90,
        84,
        95,
        103,
        94,
        82,
        89,
        88,
        101,
        86,
        97,
        92,
        95,
        103,
        88,
        118,
        84,
        93,
        97,
        95,
        107,
        120,
        99,
        124,
        106,
        102,
        126,
        115,
        101,
        123,
        101,
        96,
        111,
        107,
        103,
        94,
        93,
        106,
        113,
        111,
        101,
        96,
        94,
        85,
        106,
        104,
        100,
        83,
        84,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "45 a 64<br />2017",
      line: { color: "rgba(255,217,47,1)", dash: "solid" },
      marker: {
        color: "rgba(255,217,47,1)",
        line: { color: "rgba(255,217,47,1)" },
      },
      textfont: { color: "rgba(255,217,47,1)" },
      error_y: { color: "rgba(255,217,47,1)" },
      error_x: { color: "rgba(255,217,47,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        95,
        97,
        82,
        102,
        107,
        97,
        104,
        80,
        91,
        107,
        99,
        113,
        88,
        98,
        106,
        85,
        89,
        91,
        99,
        97,
        103,
        99,
        108,
        104,
        110,
        105,
        115,
        107,
        113,
        110,
        112,
        153,
        124,
        104,
        119,
        121,
        112,
        122,
        102,
        105,
        107,
        103,
        94,
        84,
        95,
        102,
        102,
        90,
        95,
        71,
        90,
        87,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "45 a 64<br />2018",
      line: { color: "rgba(255,217,47,1)", dash: "dash" },
      marker: {
        color: "rgba(255,217,47,1)",
        line: { color: "rgba(255,217,47,1)" },
      },
      textfont: { color: "rgba(255,217,47,1)" },
      error_y: { color: "rgba(255,217,47,1)" },
      error_x: { color: "rgba(255,217,47,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        102,
        102,
        80,
        102,
        95,
        95,
        103,
        96,
        101,
        87,
        120,
        96,
        109,
        100,
        94,
        99,
        72,
        106,
        118,
        115,
        105,
        110,
        107,
        114,
        87,
        85,
        96,
        132,
        114,
        120,
        107,
        127,
        122,
        113,
        102,
        105,
        116,
        113,
        111,
        86,
        105,
        105,
        114,
        103,
        88,
        99,
        77,
        100,
        84,
        84,
        84,
        104,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "45 a 64<br />2019",
      line: { color: "rgba(255,217,47,1)", dash: "dot" },
      marker: {
        color: "rgba(255,217,47,1)",
        line: { color: "rgba(255,217,47,1)" },
      },
      textfont: { color: "rgba(255,217,47,1)" },
      error_y: { color: "rgba(255,217,47,1)" },
      error_x: { color: "rgba(255,217,47,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        5,
        6,
        9,
        10,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        30,
        31,
        32,
        33,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        49,
        50,
      ],
      y: [
        1,
        5,
        2,
        3,
        3,
        2,
        1,
        2,
        4,
        1,
        1,
        1,
        2,
        1,
        1,
        2,
        5,
        1,
        4,
        2,
        4,
        3,
        1,
        1,
        1,
        2,
        1,
        3,
        6,
        2,
        2,
        1,
        4,
        4,
        1,
        2,
        2,
        1,
        4,
        1,
        3,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "5 a 14<br />2017",
      line: { color: "rgba(141,160,203,1)", dash: "solid" },
      marker: {
        color: "rgba(141,160,203,1)",
        line: { color: "rgba(141,160,203,1)" },
      },
      textfont: { color: "rgba(141,160,203,1)" },
      error_y: { color: "rgba(141,160,203,1)" },
      error_x: { color: "rgba(141,160,203,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        3,
        4,
        6,
        8,
        10,
        11,
        14,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        25,
        26,
        27,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        47,
        48,
        49,
        50,
        51,
      ],
      y: [
        4,
        4,
        1,
        3,
        1,
        2,
        3,
        3,
        2,
        1,
        1,
        2,
        3,
        3,
        2,
        1,
        2,
        1,
        1,
        3,
        1,
        1,
        4,
        1,
        4,
        4,
        1,
        5,
        2,
        4,
        2,
        2,
        1,
        1,
        3,
        2,
        1,
        3,
        1,
        1,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "5 a 14<br />2018",
      line: { color: "rgba(141,160,203,1)", dash: "dash" },
      marker: {
        color: "rgba(141,160,203,1)",
        line: { color: "rgba(141,160,203,1)" },
      },
      textfont: { color: "rgba(141,160,203,1)" },
      error_y: { color: "rgba(141,160,203,1)" },
      error_x: { color: "rgba(141,160,203,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        3,
        4,
        5,
        6,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        17,
        19,
        21,
        23,
        24,
        25,
        26,
        29,
        31,
        32,
        34,
        35,
        36,
        37,
        39,
        40,
        41,
        42,
        43,
        45,
        48,
        49,
        51,
        52,
      ],
      y: [
        3,
        6,
        1,
        1,
        1,
        2,
        1,
        2,
        1,
        1,
        1,
        2,
        3,
        2,
        1,
        1,
        1,
        2,
        1,
        1,
        1,
        3,
        2,
        4,
        1,
        3,
        1,
        1,
        1,
        2,
        2,
        1,
        4,
        1,
        1,
        3,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "5 a 14<br />2019",
      line: { color: "rgba(141,160,203,1)", dash: "dot" },
      marker: {
        color: "rgba(141,160,203,1)",
        line: { color: "rgba(141,160,203,1)" },
      },
      textfont: { color: "rgba(141,160,203,1)" },
      error_y: { color: "rgba(141,160,203,1)" },
      error_x: { color: "rgba(141,160,203,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        103,
        113,
        99,
        96,
        119,
        97,
        107,
        111,
        94,
        102,
        141,
        113,
        121,
        118,
        107,
        105,
        116,
        118,
        107,
        114,
        137,
        114,
        149,
        164,
        139,
        121,
        141,
        135,
        127,
        135,
        115,
        139,
        113,
        115,
        123,
        128,
        123,
        110,
        105,
        118,
        118,
        91,
        117,
        123,
        108,
        105,
        118,
        90,
        118,
        111,
        101,
        127,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "65 a 74<br />2017",
      line: { color: "rgba(229,196,148,1)", dash: "solid" },
      marker: {
        color: "rgba(229,196,148,1)",
        line: { color: "rgba(229,196,148,1)" },
      },
      textfont: { color: "rgba(229,196,148,1)" },
      error_y: { color: "rgba(229,196,148,1)" },
      error_x: { color: "rgba(229,196,148,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        115,
        122,
        97,
        118,
        106,
        102,
        107,
        91,
        112,
        108,
        102,
        124,
        101,
        106,
        110,
        113,
        99,
        115,
        117,
        118,
        137,
        123,
        137,
        139,
        132,
        141,
        146,
        146,
        145,
        130,
        141,
        150,
        158,
        119,
        153,
        154,
        150,
        126,
        133,
        112,
        113,
        131,
        112,
        130,
        124,
        113,
        128,
        118,
        107,
        108,
        108,
        120,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "65 a 74<br />2018",
      line: { color: "rgba(229,196,148,1)", dash: "dash" },
      marker: {
        color: "rgba(229,196,148,1)",
        line: { color: "rgba(229,196,148,1)" },
      },
      textfont: { color: "rgba(229,196,148,1)" },
      error_y: { color: "rgba(229,196,148,1)" },
      error_x: { color: "rgba(229,196,148,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        116,
        122,
        95,
        123,
        134,
        129,
        111,
        131,
        87,
        107,
        123,
        124,
        126,
        98,
        135,
        118,
        106,
        107,
        121,
        114,
        128,
        126,
        128,
        136,
        124,
        129,
        161,
        159,
        135,
        146,
        152,
        155,
        137,
        140,
        144,
        128,
        126,
        140,
        131,
        106,
        137,
        122,
        100,
        122,
        95,
        133,
        119,
        110,
        111,
        106,
        113,
        102,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "65 a 74<br />2019",
      line: { color: "rgba(229,196,148,1)", dash: "dot" },
      marker: {
        color: "rgba(229,196,148,1)",
        line: { color: "rgba(229,196,148,1)" },
      },
      textfont: { color: "rgba(229,196,148,1)" },
      error_y: { color: "rgba(229,196,148,1)" },
      error_x: { color: "rgba(229,196,148,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        334,
        348,
        322,
        306,
        328,
        314,
        309,
        335,
        360,
        332,
        332,
        356,
        336,
        347,
        320,
        350,
        347,
        339,
        339,
        320,
        423,
        421,
        474,
        513,
        542,
        520,
        532,
        473,
        452,
        469,
        443,
        381,
        426,
        434,
        391,
        392,
        413,
        379,
        397,
        357,
        352,
        352,
        324,
        328,
        329,
        349,
        296,
        333,
        324,
        363,
        309,
        349,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "75 y más<br />2017",
      line: { color: "rgba(179,179,179,1)", dash: "solid" },
      marker: {
        color: "rgba(179,179,179,1)",
        line: { color: "rgba(179,179,179,1)" },
      },
      textfont: { color: "rgba(179,179,179,1)" },
      error_y: { color: "rgba(179,179,179,1)" },
      error_x: { color: "rgba(179,179,179,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        351,
        340,
        303,
        301,
        322,
        332,
        287,
        329,
        295,
        309,
        321,
        308,
        306,
        302,
        347,
        353,
        301,
        317,
        340,
        324,
        398,
        410,
        427,
        443,
        492,
        493,
        468,
        493,
        437,
        485,
        487,
        500,
        513,
        511,
        535,
        466,
        476,
        437,
        460,
        403,
        439,
        400,
        353,
        341,
        352,
        373,
        344,
        330,
        296,
        351,
        310,
        351,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "75 y más<br />2018",
      line: { color: "rgba(179,179,179,1)", dash: "dash" },
      marker: {
        color: "rgba(179,179,179,1)",
        line: { color: "rgba(179,179,179,1)" },
      },
      textfont: { color: "rgba(179,179,179,1)" },
      error_y: { color: "rgba(179,179,179,1)" },
      error_x: { color: "rgba(179,179,179,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        345,
        343,
        321,
        342,
        382,
        321,
        319,
        335,
        326,
        355,
        326,
        323,
        339,
        359,
        340,
        334,
        328,
        379,
        372,
        384,
        366,
        401,
        437,
        443,
        390,
        445,
        456,
        590,
        538,
        515,
        505,
        492,
        493,
        477,
        490,
        481,
        492,
        438,
        438,
        414,
        420,
        368,
        391,
        414,
        370,
        369,
        386,
        351,
        340,
        365,
        336,
        339,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "75 y más<br />2019",
      line: { color: "rgba(179,179,179,1)", dash: "dot" },
      marker: {
        color: "rgba(179,179,179,1)",
        line: { color: "rgba(179,179,179,1)" },
      },
      textfont: { color: "rgba(179,179,179,1)" },
      error_y: { color: "rgba(179,179,179,1)" },
      error_x: { color: "rgba(179,179,179,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        7,
        5,
        5,
        5,
        7,
        4,
        7,
        7,
        9,
        4,
        5,
        4,
        4,
        7,
        4,
        3,
        6,
        2,
        7,
        5,
        3,
        4,
        1,
        8,
        6,
        11,
        7,
        4,
        2,
        6,
        4,
        6,
        9,
        6,
        7,
        6,
        4,
        4,
        5,
        7,
        4,
        7,
        7,
        5,
        1,
        8,
        2,
        8,
        5,
        3,
        10,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "menor a 1<br />2017",
      line: { color: "rgba(102,194,165,1)", dash: "solid" },
      marker: {
        color: "rgba(102,194,165,1)",
        line: { color: "rgba(102,194,165,1)" },
      },
      textfont: { color: "rgba(102,194,165,1)" },
      error_y: { color: "rgba(102,194,165,1)" },
      error_x: { color: "rgba(102,194,165,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        6,
        4,
        6,
        9,
        6,
        7,
        3,
        7,
        5,
        4,
        5,
        4,
        4,
        3,
        6,
        7,
        11,
        5,
        6,
        6,
        4,
        4,
        9,
        5,
        7,
        7,
        5,
        5,
        5,
        4,
        8,
        11,
        5,
        7,
        2,
        6,
        4,
        3,
        4,
        5,
        8,
        8,
        4,
        4,
        6,
        2,
        6,
        3,
        1,
        4,
        2,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "menor a 1<br />2018",
      line: { color: "rgba(102,194,165,1)", dash: "dash" },
      marker: {
        color: "rgba(102,194,165,1)",
        line: { color: "rgba(102,194,165,1)" },
      },
      textfont: { color: "rgba(102,194,165,1)" },
      error_y: { color: "rgba(102,194,165,1)" },
      error_x: { color: "rgba(102,194,165,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        7,
        8,
        5,
        4,
        9,
        5,
        3,
        3,
        6,
        5,
        4,
        3,
        6,
        4,
        4,
        9,
        7,
        4,
        4,
        6,
        10,
        5,
        4,
        5,
        5,
        3,
        4,
        4,
        5,
        6,
        4,
        8,
        7,
        3,
        5,
        4,
        4,
        5,
        4,
        7,
        2,
        4,
        1,
        1,
        3,
        6,
        8,
        2,
        5,
        5,
        4,
        4,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "menor a 1<br />2019",
      line: { color: "rgba(102,194,165,1)", dash: "dot" },
      marker: {
        color: "rgba(102,194,165,1)",
        line: { color: "rgba(102,194,165,1)" },
      },
      textfont: { color: "rgba(102,194,165,1)" },
      error_y: { color: "rgba(102,194,165,1)" },
      error_x: { color: "rgba(102,194,165,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        3,
        4,
        5,
        6,
        7,
        9,
        11,
        13,
        17,
        18,
        20,
        22,
        23,
        25,
        26,
        28,
        30,
        31,
        32,
        33,
        35,
        36,
        38,
        39,
        41,
        42,
        43,
        44,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        1.09,
        1.09,
        0.54,
        0.54,
        0.54,
        1.63,
        0.54,
        0.54,
        0.54,
        0.54,
        1.09,
        0.54,
        0.54,
        0.54,
        0.54,
        1.63,
        1.09,
        1.09,
        1.09,
        1.63,
        1.09,
        1.09,
        0.54,
        0.54,
        1.63,
        1.09,
        0.54,
        1.63,
        0.54,
        1.09,
        1.09,
        1.09,
        0.54,
        0.54,
        1.09,
        0.54,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "1 a 4<br />2017",
      line: { color: "rgba(252,141,98,1)", dash: "solid" },
      marker: {
        color: "rgba(252,141,98,1)",
        line: { color: "rgba(252,141,98,1)" },
      },
      textfont: { color: "rgba(252,141,98,1)" },
      error_y: { color: "rgba(252,141,98,1)" },
      error_x: { color: "rgba(252,141,98,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        7,
        9,
        12,
        15,
        16,
        17,
        18,
        20,
        23,
        24,
        25,
        26,
        28,
        30,
        31,
        33,
        35,
        36,
        37,
        38,
        39,
        41,
        42,
        43,
        44,
        45,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        1.64,
        1.09,
        0.55,
        0.55,
        0.55,
        1.09,
        1.09,
        0.55,
        1.64,
        0.55,
        0.55,
        0.55,
        1.09,
        0.55,
        1.09,
        0.55,
        2.19,
        2.19,
        0.55,
        1.64,
        1.64,
        1.09,
        1.09,
        0.55,
        0.55,
        0.55,
        1.64,
        1.09,
        0.55,
        1.09,
        2.19,
        0.55,
        0.55,
        0.55,
        0.55,
        0.55,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "1 a 4<br />2018",
      line: { color: "rgba(252,141,98,1)", dash: "dash" },
      marker: {
        color: "rgba(252,141,98,1)",
        line: { color: "rgba(252,141,98,1)" },
      },
      textfont: { color: "rgba(252,141,98,1)" },
      error_y: { color: "rgba(252,141,98,1)" },
      error_x: { color: "rgba(252,141,98,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        2,
        4,
        6,
        7,
        8,
        13,
        14,
        15,
        16,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        37,
        38,
        39,
        42,
        43,
        49,
        51,
      ],
      y: [
        0.55,
        1.1,
        1.1,
        0.55,
        0.55,
        0.55,
        1.1,
        0.55,
        1.65,
        1.1,
        1.65,
        0.55,
        0.55,
        1.1,
        1.65,
        1.1,
        0.55,
        0.55,
        0.55,
        0.55,
        1.65,
        0.55,
        0.55,
        0.55,
        2.19,
        0.55,
        1.1,
        1.65,
        1.65,
        0.55,
        1.1,
        2.19,
        1.65,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "1 a 4<br />2019",
      line: { color: "rgba(252,141,98,1)", dash: "dot" },
      marker: {
        color: "rgba(252,141,98,1)",
        line: { color: "rgba(252,141,98,1)" },
      },
      textfont: { color: "rgba(252,141,98,1)" },
      error_y: { color: "rgba(252,141,98,1)" },
      error_x: { color: "rgba(252,141,98,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        2.25,
        1.88,
        2.25,
        2.44,
        2.25,
        1.5,
        0.94,
        2.44,
        2.44,
        1.13,
        2.06,
        2.25,
        3.94,
        1.88,
        2.44,
        1.69,
        1.31,
        1.13,
        1.88,
        1.88,
        2.06,
        1.13,
        2.25,
        1.31,
        0.56,
        1.5,
        0.75,
        1.5,
        2.06,
        2.25,
        2.63,
        0.94,
        0.19,
        1.69,
        1.88,
        2.44,
        2.25,
        1.88,
        0.75,
        1.5,
        1.13,
        1.69,
        1.5,
        1.13,
        2.44,
        1.88,
        1.31,
        0.75,
        2.06,
        1.5,
        1.88,
        2.06,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "15 a 24<br />2017",
      line: { color: "rgba(231,138,195,1)", dash: "solid" },
      marker: {
        color: "rgba(231,138,195,1)",
        line: { color: "rgba(231,138,195,1)" },
      },
      textfont: { color: "rgba(231,138,195,1)" },
      error_y: { color: "rgba(231,138,195,1)" },
      error_x: { color: "rgba(231,138,195,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        1.88,
        2.26,
        3.01,
        2.26,
        0.75,
        2.26,
        1.32,
        1.5,
        1.32,
        1.32,
        3.01,
        1.88,
        2.82,
        1.13,
        1.13,
        1.88,
        1.13,
        2.07,
        1.32,
        0.94,
        2.44,
        1.13,
        2.26,
        1.32,
        2.07,
        1.69,
        2.44,
        2.26,
        1.69,
        1.13,
        2.26,
        0.94,
        2.26,
        1.13,
        1.32,
        1.88,
        1.69,
        2.07,
        3.01,
        1.13,
        2.82,
        1.5,
        1.69,
        2.26,
        2.07,
        1.32,
        1.13,
        1.69,
        0.56,
        0.94,
        2.63,
        2.26,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "15 a 24<br />2018",
      line: { color: "rgba(231,138,195,1)", dash: "dash" },
      marker: {
        color: "rgba(231,138,195,1)",
        line: { color: "rgba(231,138,195,1)" },
      },
      textfont: { color: "rgba(231,138,195,1)" },
      error_y: { color: "rgba(231,138,195,1)" },
      error_x: { color: "rgba(231,138,195,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        1.51,
        2.27,
        0.95,
        1.7,
        1.32,
        2.08,
        1.14,
        2.08,
        1.51,
        1.32,
        2.08,
        1.89,
        2.27,
        2.08,
        1.51,
        0.95,
        1.51,
        0.95,
        1.14,
        1.14,
        3.22,
        1.7,
        1.89,
        0.19,
        1.7,
        0.76,
        1.32,
        1.7,
        2.84,
        0.95,
        1.51,
        2.08,
        2.08,
        1.32,
        2.08,
        1.14,
        1.32,
        2.84,
        1.89,
        1.89,
        1.14,
        0.95,
        1.32,
        0.95,
        2.65,
        2.08,
        3.03,
        1.7,
        1.14,
        1.51,
        2.65,
        3.6,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "15 a 24<br />2019",
      line: { color: "rgba(231,138,195,1)", dash: "dot" },
      marker: {
        color: "rgba(231,138,195,1)",
        line: { color: "rgba(231,138,195,1)" },
      },
      textfont: { color: "rgba(231,138,195,1)" },
      error_y: { color: "rgba(231,138,195,1)" },
      error_x: { color: "rgba(231,138,195,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        2.37,
        3.3,
        3.82,
        4.34,
        2.58,
        3.2,
        2.58,
        3.3,
        2.48,
        3.51,
        2.79,
        2.58,
        1.65,
        2.48,
        1.86,
        2.17,
        3.1,
        1.86,
        2.48,
        2.37,
        2.17,
        2.89,
        2.79,
        2.27,
        2.17,
        2.79,
        3.3,
        2.79,
        3.3,
        1.86,
        3.1,
        2.27,
        3.41,
        3.1,
        2.48,
        3.3,
        1.76,
        2.68,
        2.58,
        2.79,
        2.27,
        2.99,
        2.27,
        3.2,
        2.79,
        2.79,
        1.96,
        3.3,
        2.06,
        3.92,
        2.68,
        3.92,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "25 a 44<br />2017",
      line: { color: "rgba(166,216,84,1)", dash: "solid" },
      marker: {
        color: "rgba(166,216,84,1)",
        line: { color: "rgba(166,216,84,1)" },
      },
      textfont: { color: "rgba(166,216,84,1)" },
      error_y: { color: "rgba(166,216,84,1)" },
      error_x: { color: "rgba(166,216,84,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        3.18,
        2.77,
        2.05,
        2.67,
        3.08,
        4,
        2.36,
        3.59,
        2.97,
        1.95,
        2.56,
        2.67,
        2.56,
        2.46,
        2.56,
        3.8,
        2.67,
        3.08,
        3.28,
        2.67,
        3.18,
        2.05,
        2.87,
        2.46,
        2.56,
        2.87,
        2.15,
        3.9,
        3.8,
        2.46,
        2.87,
        3.59,
        2.87,
        4,
        3.08,
        2.36,
        3.49,
        3.49,
        2.87,
        2.87,
        2.97,
        2.26,
        2.46,
        2.77,
        3.38,
        2.67,
        3.8,
        3.08,
        2.77,
        2.77,
        2.67,
        2.87,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "25 a 44<br />2018",
      line: { color: "rgba(166,216,84,1)", dash: "dash" },
      marker: {
        color: "rgba(166,216,84,1)",
        line: { color: "rgba(166,216,84,1)" },
      },
      textfont: { color: "rgba(166,216,84,1)" },
      error_y: { color: "rgba(166,216,84,1)" },
      error_x: { color: "rgba(166,216,84,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        3.16,
        3.67,
        2.14,
        3.16,
        4.28,
        2.85,
        2.75,
        2.34,
        1.73,
        3.77,
        1.83,
        2.14,
        3.46,
        3.36,
        2.75,
        3.46,
        2.85,
        3.36,
        2.85,
        2.44,
        2.24,
        2.55,
        2.55,
        3.16,
        3.67,
        3.16,
        3.97,
        2.14,
        2.65,
        2.95,
        3.77,
        2.85,
        2.95,
        2.44,
        2.85,
        2.85,
        2.65,
        3.26,
        2.24,
        3.16,
        2.55,
        3.46,
        3.87,
        2.85,
        2.55,
        1.83,
        1.73,
        2.95,
        2.44,
        2.95,
        2.75,
        4.38,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "25 a 44<br />2019",
      line: { color: "rgba(166,216,84,1)", dash: "dot" },
      marker: {
        color: "rgba(166,216,84,1)",
        line: { color: "rgba(166,216,84,1)" },
      },
      textfont: { color: "rgba(166,216,84,1)" },
      error_y: { color: "rgba(166,216,84,1)" },
      error_x: { color: "rgba(166,216,84,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        9.15,
        11.31,
        13.09,
        11.44,
        10.67,
        12.07,
        13.09,
        11.94,
        10.42,
        11.31,
        11.18,
        12.83,
        10.93,
        12.33,
        11.69,
        12.07,
        13.09,
        11.18,
        14.99,
        10.67,
        11.82,
        12.33,
        12.07,
        13.6,
        15.25,
        12.58,
        15.76,
        13.47,
        12.96,
        16.01,
        14.61,
        12.83,
        15.63,
        12.83,
        12.2,
        14.1,
        13.6,
        13.09,
        11.94,
        11.82,
        13.47,
        14.36,
        14.1,
        12.83,
        12.2,
        11.94,
        10.8,
        13.47,
        13.21,
        12.71,
        10.55,
        10.67,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "45 a 64<br />2017",
      line: { color: "rgba(255,217,47,1)", dash: "solid" },
      marker: {
        color: "rgba(255,217,47,1)",
        line: { color: "rgba(255,217,47,1)" },
      },
      textfont: { color: "rgba(255,217,47,1)" },
      error_y: { color: "rgba(255,217,47,1)" },
      error_x: { color: "rgba(255,217,47,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        11.95,
        12.21,
        10.32,
        12.83,
        13.46,
        12.21,
        13.09,
        10.07,
        11.45,
        13.46,
        12.46,
        14.22,
        11.07,
        12.33,
        13.34,
        10.7,
        11.2,
        11.45,
        12.46,
        12.21,
        12.96,
        12.46,
        13.59,
        13.09,
        13.84,
        13.21,
        14.47,
        13.46,
        14.22,
        13.84,
        14.09,
        19.25,
        15.6,
        13.09,
        14.97,
        15.23,
        14.09,
        15.35,
        12.83,
        13.21,
        13.46,
        12.96,
        11.83,
        10.57,
        11.95,
        12.83,
        12.83,
        11.32,
        11.95,
        8.93,
        11.32,
        10.95,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "45 a 64<br />2018",
      line: { color: "rgba(255,217,47,1)", dash: "dash" },
      marker: {
        color: "rgba(255,217,47,1)",
        line: { color: "rgba(255,217,47,1)" },
      },
      textfont: { color: "rgba(255,217,47,1)" },
      error_y: { color: "rgba(255,217,47,1)" },
      error_x: { color: "rgba(255,217,47,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        12.71,
        12.71,
        9.97,
        12.71,
        11.84,
        11.84,
        12.84,
        11.97,
        12.59,
        10.84,
        14.96,
        11.97,
        13.59,
        12.46,
        11.72,
        12.34,
        8.97,
        13.21,
        14.71,
        14.33,
        13.09,
        13.71,
        13.34,
        14.21,
        10.84,
        10.59,
        11.97,
        16.45,
        14.21,
        14.96,
        13.34,
        15.83,
        15.21,
        14.08,
        12.71,
        13.09,
        14.46,
        14.08,
        13.84,
        10.72,
        13.09,
        13.09,
        14.21,
        12.84,
        10.97,
        12.34,
        9.6,
        12.46,
        10.47,
        10.47,
        10.47,
        12.96,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "45 a 64<br />2019",
      line: { color: "rgba(255,217,47,1)", dash: "dot" },
      marker: {
        color: "rgba(255,217,47,1)",
        line: { color: "rgba(255,217,47,1)" },
      },
      textfont: { color: "rgba(255,217,47,1)" },
      error_y: { color: "rgba(255,217,47,1)" },
      error_x: { color: "rgba(255,217,47,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        5,
        6,
        9,
        10,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        30,
        31,
        32,
        33,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        49,
        50,
      ],
      y: [
        0.21,
        1.04,
        0.42,
        0.62,
        0.62,
        0.42,
        0.21,
        0.42,
        0.83,
        0.21,
        0.21,
        0.21,
        0.42,
        0.21,
        0.21,
        0.42,
        1.04,
        0.21,
        0.83,
        0.42,
        0.83,
        0.62,
        0.21,
        0.21,
        0.21,
        0.42,
        0.21,
        0.62,
        1.25,
        0.42,
        0.42,
        0.21,
        0.83,
        0.83,
        0.21,
        0.42,
        0.42,
        0.21,
        0.83,
        0.21,
        0.62,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "5 a 14<br />2017",
      line: { color: "rgba(141,160,203,1)", dash: "solid" },
      marker: {
        color: "rgba(141,160,203,1)",
        line: { color: "rgba(141,160,203,1)" },
      },
      textfont: { color: "rgba(141,160,203,1)" },
      error_y: { color: "rgba(141,160,203,1)" },
      error_x: { color: "rgba(141,160,203,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        3,
        4,
        6,
        8,
        10,
        11,
        14,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        25,
        26,
        27,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        47,
        48,
        49,
        50,
        51,
      ],
      y: [
        0.84,
        0.84,
        0.21,
        0.63,
        0.21,
        0.42,
        0.63,
        0.63,
        0.42,
        0.21,
        0.21,
        0.42,
        0.63,
        0.63,
        0.42,
        0.21,
        0.42,
        0.21,
        0.21,
        0.63,
        0.21,
        0.21,
        0.84,
        0.21,
        0.84,
        0.84,
        0.21,
        1.05,
        0.42,
        0.84,
        0.42,
        0.42,
        0.21,
        0.21,
        0.63,
        0.42,
        0.21,
        0.63,
        0.21,
        0.21,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "5 a 14<br />2018",
      line: { color: "rgba(141,160,203,1)", dash: "dash" },
      marker: {
        color: "rgba(141,160,203,1)",
        line: { color: "rgba(141,160,203,1)" },
      },
      textfont: { color: "rgba(141,160,203,1)" },
      error_y: { color: "rgba(141,160,203,1)" },
      error_x: { color: "rgba(141,160,203,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        3,
        4,
        5,
        6,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        17,
        19,
        21,
        23,
        24,
        25,
        26,
        29,
        31,
        32,
        34,
        35,
        36,
        37,
        39,
        40,
        41,
        42,
        43,
        45,
        48,
        49,
        51,
        52,
      ],
      y: [
        0.64,
        1.27,
        0.21,
        0.21,
        0.21,
        0.42,
        0.21,
        0.42,
        0.21,
        0.21,
        0.21,
        0.42,
        0.64,
        0.42,
        0.21,
        0.21,
        0.21,
        0.42,
        0.21,
        0.21,
        0.21,
        0.64,
        0.42,
        0.85,
        0.21,
        0.64,
        0.21,
        0.21,
        0.21,
        0.42,
        0.42,
        0.21,
        0.85,
        0.21,
        0.21,
        0.64,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "5 a 14<br />2019",
      line: { color: "rgba(141,160,203,1)", dash: "dot" },
      marker: {
        color: "rgba(141,160,203,1)",
        line: { color: "rgba(141,160,203,1)" },
      },
      textfont: { color: "rgba(141,160,203,1)" },
      error_y: { color: "rgba(141,160,203,1)" },
      error_x: { color: "rgba(141,160,203,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        39.12,
        42.91,
        37.6,
        36.46,
        45.19,
        36.84,
        40.63,
        42.15,
        35.7,
        38.74,
        53.55,
        42.91,
        45.95,
        44.81,
        40.63,
        39.87,
        44.05,
        44.81,
        40.63,
        43.29,
        52.03,
        43.29,
        56.58,
        62.28,
        52.79,
        45.95,
        53.55,
        51.27,
        48.23,
        51.27,
        43.67,
        52.79,
        42.91,
        43.67,
        46.71,
        48.61,
        46.71,
        41.77,
        39.87,
        44.81,
        44.81,
        34.56,
        44.43,
        46.71,
        41.01,
        39.87,
        44.81,
        34.18,
        44.81,
        42.15,
        38.36,
        48.23,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "65 a 74<br />2017",
      line: { color: "rgba(229,196,148,1)", dash: "solid" },
      marker: {
        color: "rgba(229,196,148,1)",
        line: { color: "rgba(229,196,148,1)" },
      },
      textfont: { color: "rgba(229,196,148,1)" },
      error_y: { color: "rgba(229,196,148,1)" },
      error_x: { color: "rgba(229,196,148,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        43,
        45.62,
        36.27,
        44.12,
        39.63,
        38.14,
        40.01,
        34.03,
        41.88,
        40.38,
        38.14,
        46.36,
        37.76,
        39.63,
        41.13,
        42.25,
        37.02,
        43,
        43.75,
        44.12,
        51.23,
        45.99,
        51.23,
        51.97,
        49.36,
        52.72,
        54.59,
        54.59,
        54.22,
        48.61,
        52.72,
        56.09,
        59.08,
        44.49,
        57.21,
        57.58,
        56.09,
        47.11,
        49.73,
        41.88,
        42.25,
        48.98,
        41.88,
        48.61,
        46.36,
        42.25,
        47.86,
        44.12,
        40.01,
        40.38,
        40.38,
        44.87,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "65 a 74<br />2018",
      line: { color: "rgba(229,196,148,1)", dash: "dash" },
      marker: {
        color: "rgba(229,196,148,1)",
        line: { color: "rgba(229,196,148,1)" },
      },
      textfont: { color: "rgba(229,196,148,1)" },
      error_y: { color: "rgba(229,196,148,1)" },
      error_x: { color: "rgba(229,196,148,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        42.6,
        44.81,
        34.89,
        45.17,
        49.21,
        47.38,
        40.77,
        48.11,
        31.95,
        39.3,
        45.17,
        45.54,
        46.27,
        35.99,
        49.58,
        43.34,
        38.93,
        39.3,
        44.44,
        41.87,
        47.01,
        46.27,
        47.01,
        49.95,
        45.54,
        47.38,
        59.13,
        58.39,
        49.58,
        53.62,
        55.82,
        56.93,
        50.31,
        51.42,
        52.89,
        47.01,
        46.27,
        51.42,
        48.11,
        38.93,
        50.31,
        44.81,
        36.73,
        44.81,
        34.89,
        48.85,
        43.7,
        40.4,
        40.77,
        38.93,
        41.5,
        37.46,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "65 a 74<br />2019",
      line: { color: "rgba(229,196,148,1)", dash: "dot" },
      marker: {
        color: "rgba(229,196,148,1)",
        line: { color: "rgba(229,196,148,1)" },
      },
      textfont: { color: "rgba(229,196,148,1)" },
      error_y: { color: "rgba(229,196,148,1)" },
      error_x: { color: "rgba(229,196,148,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        144.52,
        150.58,
        139.33,
        132.4,
        141.92,
        135.87,
        133.7,
        144.95,
        155.77,
        143.65,
        143.65,
        154.04,
        145.39,
        150.14,
        138.46,
        151.44,
        150.14,
        146.68,
        146.68,
        138.46,
        183.03,
        182.16,
        205.1,
        221.97,
        234.52,
        225,
        230.19,
        204.66,
        195.58,
        202.93,
        191.68,
        164.86,
        184.33,
        187.79,
        169.18,
        169.62,
        178.7,
        163.99,
        171.78,
        154.47,
        152.31,
        152.31,
        140.19,
        141.92,
        142.36,
        151.01,
        128.08,
        144.09,
        140.19,
        157.07,
        133.7,
        151.01,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "75 y más<br />2017",
      line: { color: "rgba(179,179,179,1)", dash: "solid" },
      marker: {
        color: "rgba(179,179,179,1)",
        line: { color: "rgba(179,179,179,1)" },
      },
      textfont: { color: "rgba(179,179,179,1)" },
      error_y: { color: "rgba(179,179,179,1)" },
      error_x: { color: "rgba(179,179,179,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        150.69,
        145.97,
        130.09,
        129.23,
        138.24,
        142.54,
        123.22,
        141.25,
        126.65,
        132.66,
        137.81,
        132.23,
        131.37,
        129.66,
        148.98,
        151.55,
        129.23,
        136.1,
        145.97,
        139.1,
        170.87,
        176.02,
        183.32,
        190.19,
        211.23,
        211.66,
        200.92,
        211.66,
        187.61,
        208.22,
        209.08,
        214.66,
        220.24,
        219.38,
        229.69,
        200.07,
        204.36,
        187.61,
        197.49,
        173.02,
        188.47,
        171.73,
        151.55,
        146.4,
        151.12,
        160.14,
        147.69,
        141.68,
        127.08,
        150.69,
        133.09,
        150.69,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "75 y más<br />2018",
      line: { color: "rgba(179,179,179,1)", dash: "dash" },
      marker: {
        color: "rgba(179,179,179,1)",
        line: { color: "rgba(179,179,179,1)" },
      },
      textfont: { color: "rgba(179,179,179,1)" },
      error_y: { color: "rgba(179,179,179,1)" },
      error_x: { color: "rgba(179,179,179,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        146.82,
        145.97,
        136.61,
        145.54,
        162.56,
        136.61,
        135.75,
        142.56,
        138.73,
        151.07,
        138.73,
        137.46,
        144.27,
        152.78,
        144.69,
        142.14,
        139.58,
        161.29,
        158.31,
        163.42,
        155.76,
        170.65,
        185.97,
        188.52,
        165.97,
        189.38,
        194.06,
        251.08,
        228.95,
        219.16,
        214.91,
        209.38,
        209.8,
        202.99,
        208.53,
        204.7,
        209.38,
        186.4,
        186.4,
        176.18,
        178.74,
        156.61,
        166.4,
        176.18,
        157.46,
        157.03,
        164.27,
        149.37,
        144.69,
        155.33,
        142.99,
        144.27,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "75 y más<br />2019",
      line: { color: "rgba(179,179,179,1)", dash: "dot" },
      marker: {
        color: "rgba(179,179,179,1)",
        line: { color: "rgba(179,179,179,1)" },
      },
      textfont: { color: "rgba(179,179,179,1)" },
      error_y: { color: "rgba(179,179,179,1)" },
      error_x: { color: "rgba(179,179,179,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        15.36,
        10.97,
        10.97,
        10.97,
        15.36,
        8.78,
        15.36,
        15.36,
        19.74,
        8.78,
        10.97,
        8.78,
        8.78,
        15.36,
        8.78,
        6.58,
        13.16,
        4.39,
        15.36,
        10.97,
        6.58,
        8.78,
        2.19,
        17.55,
        13.16,
        24.13,
        15.36,
        8.78,
        4.39,
        13.16,
        8.78,
        13.16,
        19.74,
        13.16,
        15.36,
        13.16,
        8.78,
        8.78,
        10.97,
        15.36,
        8.78,
        15.36,
        15.36,
        10.97,
        2.19,
        17.55,
        4.39,
        17.55,
        10.97,
        6.58,
        21.94,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "menor a 1<br />2017",
      line: { color: "rgba(102,194,165,1)", dash: "solid" },
      marker: {
        color: "rgba(102,194,165,1)",
        line: { color: "rgba(102,194,165,1)" },
      },
      textfont: { color: "rgba(102,194,165,1)" },
      error_y: { color: "rgba(102,194,165,1)" },
      error_x: { color: "rgba(102,194,165,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        13.21,
        8.81,
        13.21,
        19.82,
        13.21,
        15.42,
        6.61,
        15.42,
        11.01,
        8.81,
        11.01,
        8.81,
        8.81,
        6.61,
        13.21,
        15.42,
        24.22,
        11.01,
        13.21,
        13.21,
        8.81,
        8.81,
        19.82,
        11.01,
        15.42,
        15.42,
        11.01,
        11.01,
        11.01,
        8.81,
        17.62,
        24.22,
        11.01,
        15.42,
        4.4,
        13.21,
        8.81,
        6.61,
        8.81,
        11.01,
        17.62,
        17.62,
        8.81,
        8.81,
        13.21,
        4.4,
        13.21,
        6.61,
        2.2,
        8.81,
        4.4,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "menor a 1<br />2018",
      line: { color: "rgba(102,194,165,1)", dash: "dash" },
      marker: {
        color: "rgba(102,194,165,1)",
        line: { color: "rgba(102,194,165,1)" },
      },
      textfont: { color: "rgba(102,194,165,1)" },
      error_y: { color: "rgba(102,194,165,1)" },
      error_x: { color: "rgba(102,194,165,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        15.47,
        17.69,
        11.05,
        8.84,
        19.9,
        11.05,
        6.63,
        6.63,
        13.26,
        11.05,
        8.84,
        6.63,
        13.26,
        8.84,
        8.84,
        19.9,
        15.47,
        8.84,
        8.84,
        13.26,
        22.11,
        11.05,
        8.84,
        11.05,
        11.05,
        6.63,
        8.84,
        8.84,
        11.05,
        13.26,
        8.84,
        17.69,
        15.47,
        6.63,
        11.05,
        8.84,
        8.84,
        11.05,
        8.84,
        15.47,
        4.42,
        8.84,
        2.21,
        2.21,
        6.63,
        13.26,
        17.69,
        4.42,
        11.05,
        11.05,
        8.84,
        8.84,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "menor a 1<br />2019",
      line: { color: "rgba(102,194,165,1)", dash: "dot" },
      marker: {
        color: "rgba(102,194,165,1)",
        line: { color: "rgba(102,194,165,1)" },
      },
      textfont: { color: "rgba(102,194,165,1)" },
      error_y: { color: "rgba(102,194,165,1)" },
      error_x: { color: "rgba(102,194,165,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
  ],
  highlight: {
    on: "plotly_click",
    persistent: false,
    dynamic: false,
    selectize: false,
    opacityDim: 0.2,
    selected: { opacity: 1 },
    debounce: 0,
  },
  shinyEvents: [
    "plotly_hover",
    "plotly_click",
    "plotly_selected",
    "plotly_relayout",
    "plotly_brushed",
    "plotly_brushing",
    "plotly_clickannotation",
    "plotly_doubleclick",
    "plotly_deselect",
    "plotly_afterplot",
    "plotly_sunburstclick",
  ],
  base_url: "https://plot.ly",
}