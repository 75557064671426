export default {
  visdat: { "117446664cc0": ["function () ", "plotlyVisDat"] },
  cur_data: "117446664cc0",
  attrs: {
    "117446664cc0": {
      alpha_stroke: 1,
      sizes: [10, 100],
      spans: [1, 20],
      x: {},
      y: {},
      color: {},
      linetype: {},
      type: "scatter",
      mode: "line",
      inherit: true,
    },
    "117446664cc0.1": {
      alpha_stroke: 1,
      sizes: [10, 100],
      spans: [1, 20],
      x: {},
      y: {},
      color: {},
      linetype: {},
      type: "scatter",
      mode: "line",
      visible: false,
      inherit: true,
    },
  },
  layout: {
    margin: { b: 40, l: 60, t: 25, r: 10 },
    xaxis: {
      domain: [0, 1],
      automargin: true,
      title: "Semana epidemiológica",
    },
    yaxis: { domain: [0, 1], automargin: true, title: "Total" },
    legend: { title: { text: "<b> Año </b>" } },
    hovermode: "compare",
    updatemenus: [
      {
        active: 0,
        buttons: [
          {
            label: "Total",
            method: "update",
            args: [
              {
                visible: [
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                ],
              },
              { yaxis: { title: "Total" } },
            ],
          },
          {
            label: "Tasa",
            method: "update",
            args: [
              {
                visible: [
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                ],
              },
              { yaxis: { title: "Tasa cada 100.000 hab." } },
            ],
          },
        ],
      },
    ],
    showlegend: true,
  },
  source: "A",
  config: { showSendToCloud: false, locale: "es" },
  data: [
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        272,
        284,
        264,
        289,
        285,
        253,
        282,
        285,
        294,
        290,
        282,
        301,
        295,
        319,
        252,
        279,
        302,
        304,
        308,
        284,
        343,
        338,
        395,
        410,
        413,
        421,
        428,
        405,
        379,
        376,
        357,
        331,
        362,
        350,
        331,
        346,
        351,
        325,
        344,
        315,
        300,
        283,
        287,
        275,
        279,
        303,
        254,
        279,
        289,
        315,
        246,
        289,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "2017<br />Femenino",
      line: { color: "rgba(102,194,165,1)", dash: "solid" },
      marker: {
        color: "rgba(102,194,165,1)",
        line: { color: "rgba(102,194,165,1)" },
      },
      textfont: { color: "rgba(102,194,165,1)" },
      error_y: { color: "rgba(102,194,165,1)" },
      error_x: { color: "rgba(102,194,165,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        282,
        318,
        318,
        264,
        294,
        300,
        277,
        308,
        292,
        278,
        323,
        310,
        292,
        288,
        303,
        306,
        310,
        271,
        298,
        274,
        348,
        340,
        368,
        416,
        421,
        372,
        417,
        351,
        348,
        395,
        367,
        329,
        346,
        345,
        322,
        341,
        331,
        311,
        287,
        294,
        318,
        321,
        306,
        325,
        303,
        286,
        285,
        291,
        299,
        315,
        288,
        331,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "2017<br />Masculino",
      line: { color: "rgba(102,194,165,1)", dash: "dash" },
      marker: {
        color: "rgba(102,194,165,1)",
        line: { color: "rgba(102,194,165,1)" },
      },
      textfont: { color: "rgba(102,194,165,1)" },
      error_y: { color: "rgba(102,194,165,1)" },
      error_x: { color: "rgba(102,194,165,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        290,
        298,
        251,
        284,
        289,
        271,
        248,
        267,
        260,
        299,
        308,
        284,
        258,
        284,
        305,
        287,
        286,
        282,
        269,
        271,
        322,
        329,
        343,
        365,
        402,
        378,
        364,
        414,
        366,
        388,
        398,
        417,
        429,
        398,
        445,
        406,
        403,
        376,
        392,
        329,
        336,
        353,
        301,
        310,
        289,
        294,
        289,
        266,
        259,
        282,
        262,
        281,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "2018<br />Femenino",
      line: { color: "rgba(252,141,98,1)", dash: "solid" },
      marker: {
        color: "rgba(252,141,98,1)",
        line: { color: "rgba(252,141,98,1)" },
      },
      textfont: { color: "rgba(252,141,98,1)" },
      error_y: { color: "rgba(252,141,98,1)" },
      error_x: { color: "rgba(252,141,98,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        326,
        308,
        278,
        287,
        289,
        322,
        287,
        285,
        281,
        258,
        263,
        304,
        284,
        261,
        292,
        320,
        244,
        296,
        333,
        310,
        371,
        336,
        375,
        364,
        377,
        412,
        408,
        391,
        381,
        377,
        391,
        435,
        424,
        388,
        413,
        378,
        387,
        365,
        354,
        333,
        377,
        324,
        301,
        293,
        339,
        333,
        335,
        319,
        276,
        283,
        294,
        321,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "2018<br />Masculino",
      line: { color: "rgba(252,141,98,1)", dash: "dash" },
      marker: {
        color: "rgba(252,141,98,1)",
        line: { color: "rgba(252,141,98,1)" },
      },
      textfont: { color: "rgba(252,141,98,1)" },
      error_y: { color: "rgba(252,141,98,1)" },
      error_x: { color: "rgba(252,141,98,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        308,
        299,
        274,
        313,
        347,
        279,
        268,
        290,
        261,
        312,
        314,
        260,
        285,
        292,
        307,
        297,
        258,
        291,
        322,
        326,
        320,
        338,
        359,
        358,
        333,
        369,
        378,
        449,
        441,
        429,
        409,
        408,
        406,
        379,
        389,
        392,
        392,
        374,
        367,
        375,
        332,
        338,
        361,
        352,
        300,
        309,
        302,
        312,
        292,
        296,
        297,
        287,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "2019<br />Femenino",
      line: { color: "rgba(141,160,203,1)", dash: "solid" },
      marker: {
        color: "rgba(141,160,203,1)",
        line: { color: "rgba(141,160,203,1)" },
      },
      textfont: { color: "rgba(141,160,203,1)" },
      error_y: { color: "rgba(141,160,203,1)" },
      error_x: { color: "rgba(141,160,203,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        304,
        326,
        259,
        302,
        323,
        313,
        304,
        312,
        285,
        289,
        289,
        318,
        343,
        319,
        304,
        305,
        294,
        345,
        333,
        325,
        330,
        341,
        357,
        376,
        321,
        332,
        386,
        469,
        395,
        396,
        407,
        420,
        394,
        393,
        397,
        361,
        385,
        373,
        354,
        281,
        364,
        303,
        294,
        321,
        296,
        328,
        321,
        294,
        283,
        303,
        285,
        327,
      ],
      type: "scatter",
      mode: "line+lines",
      name: "2019<br />Masculino",
      line: { color: "rgba(141,160,203,1)", dash: "dash" },
      marker: {
        color: "rgba(141,160,203,1)",
        line: { color: "rgba(141,160,203,1)" },
      },
      textfont: { color: "rgba(141,160,203,1)" },
      error_y: { color: "rgba(141,160,203,1)" },
      error_x: { color: "rgba(141,160,203,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        15.09,
        15.76,
        14.65,
        16.04,
        15.81,
        14.04,
        15.65,
        15.81,
        16.31,
        16.09,
        15.65,
        16.7,
        16.37,
        17.7,
        13.98,
        15.48,
        16.76,
        16.87,
        17.09,
        15.76,
        19.03,
        18.76,
        21.92,
        22.75,
        22.92,
        23.36,
        23.75,
        22.47,
        21.03,
        20.86,
        19.81,
        18.37,
        20.09,
        19.42,
        18.37,
        19.2,
        19.48,
        18.03,
        19.09,
        17.48,
        16.65,
        15.7,
        15.93,
        15.26,
        15.48,
        16.81,
        14.09,
        15.48,
        16.04,
        17.48,
        13.65,
        16.04,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "2017<br />Femenino",
      line: { color: "rgba(102,194,165,1)", dash: "solid" },
      marker: {
        color: "rgba(102,194,165,1)",
        line: { color: "rgba(102,194,165,1)" },
      },
      textfont: { color: "rgba(102,194,165,1)" },
      error_y: { color: "rgba(102,194,165,1)" },
      error_x: { color: "rgba(102,194,165,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        16.68,
        18.8,
        18.8,
        15.61,
        17.39,
        17.74,
        16.38,
        18.21,
        17.27,
        16.44,
        19.1,
        18.33,
        17.27,
        17.03,
        17.92,
        18.09,
        18.33,
        16.03,
        17.62,
        16.2,
        20.58,
        20.11,
        21.76,
        24.6,
        24.9,
        22,
        24.66,
        20.76,
        20.58,
        23.36,
        21.7,
        19.45,
        20.46,
        20.4,
        19.04,
        20.16,
        19.57,
        18.39,
        16.97,
        17.39,
        18.8,
        18.98,
        18.09,
        19.22,
        17.92,
        16.91,
        16.85,
        17.21,
        17.68,
        18.63,
        17.03,
        19.57,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "2017<br />Masculino",
      line: { color: "rgba(102,194,165,1)", dash: "dash" },
      marker: {
        color: "rgba(102,194,165,1)",
        line: { color: "rgba(102,194,165,1)" },
      },
      textfont: { color: "rgba(102,194,165,1)" },
      error_y: { color: "rgba(102,194,165,1)" },
      error_x: { color: "rgba(102,194,165,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        16.04,
        16.48,
        13.88,
        15.71,
        15.98,
        14.99,
        13.72,
        14.77,
        14.38,
        16.54,
        17.04,
        15.71,
        14.27,
        15.71,
        16.87,
        15.87,
        15.82,
        15.6,
        14.88,
        14.99,
        17.81,
        18.2,
        18.97,
        20.19,
        22.23,
        20.91,
        20.13,
        22.9,
        20.24,
        21.46,
        22.01,
        23.06,
        23.73,
        22.01,
        24.61,
        22.46,
        22.29,
        20.8,
        21.68,
        18.2,
        18.58,
        19.52,
        16.65,
        17.15,
        15.98,
        16.26,
        15.98,
        14.71,
        14.33,
        15.6,
        14.49,
        15.54,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "2018<br />Femenino",
      line: { color: "rgba(252,141,98,1)", dash: "solid" },
      marker: {
        color: "rgba(252,141,98,1)",
        line: { color: "rgba(252,141,98,1)" },
      },
      textfont: { color: "rgba(252,141,98,1)" },
      error_y: { color: "rgba(252,141,98,1)" },
      error_x: { color: "rgba(252,141,98,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        19.2,
        18.14,
        16.37,
        16.9,
        17.02,
        18.96,
        16.9,
        16.78,
        16.55,
        15.19,
        15.49,
        17.9,
        16.73,
        15.37,
        17.2,
        18.85,
        14.37,
        17.43,
        19.61,
        18.26,
        21.85,
        19.79,
        22.09,
        21.44,
        22.2,
        24.26,
        24.03,
        23.03,
        22.44,
        22.2,
        23.03,
        25.62,
        24.97,
        22.85,
        24.32,
        22.26,
        22.79,
        21.5,
        20.85,
        19.61,
        22.2,
        19.08,
        17.73,
        17.26,
        19.96,
        19.61,
        19.73,
        18.79,
        16.25,
        16.67,
        17.31,
        18.9,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "2018<br />Masculino",
      line: { color: "rgba(252,141,98,1)", dash: "dash" },
      marker: {
        color: "rgba(252,141,98,1)",
        line: { color: "rgba(252,141,98,1)" },
      },
      textfont: { color: "rgba(252,141,98,1)" },
      error_y: { color: "rgba(252,141,98,1)" },
      error_x: { color: "rgba(252,141,98,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        16.98,
        16.48,
        15.11,
        17.26,
        19.13,
        15.38,
        14.78,
        15.99,
        14.39,
        17.2,
        17.31,
        14.33,
        15.71,
        16.1,
        16.93,
        16.37,
        14.22,
        16.04,
        17.75,
        17.97,
        17.64,
        18.64,
        19.79,
        19.74,
        18.36,
        20.34,
        20.84,
        24.76,
        24.31,
        23.65,
        22.55,
        22.49,
        22.38,
        20.9,
        21.45,
        21.61,
        21.61,
        20.62,
        20.23,
        20.68,
        18.3,
        18.64,
        19.9,
        19.41,
        16.54,
        17.04,
        16.65,
        17.2,
        16.1,
        16.32,
        16.37,
        15.82,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "2019<br />Femenino",
      line: { color: "rgba(141,160,203,1)", dash: "solid" },
      marker: {
        color: "rgba(141,160,203,1)",
        line: { color: "rgba(141,160,203,1)" },
      },
      textfont: { color: "rgba(141,160,203,1)" },
      error_y: { color: "rgba(141,160,203,1)" },
      error_x: { color: "rgba(141,160,203,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
    {
      x: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
      ],
      y: [
        17.83,
        19.12,
        15.19,
        17.71,
        18.95,
        18.36,
        17.83,
        18.3,
        16.72,
        16.95,
        16.95,
        18.65,
        20.12,
        18.71,
        17.83,
        17.89,
        17.25,
        20.24,
        19.53,
        19.06,
        19.36,
        20,
        20.94,
        22.06,
        18.83,
        19.47,
        22.64,
        27.51,
        23.17,
        23.23,
        23.87,
        24.64,
        23.11,
        23.05,
        23.29,
        21.18,
        22.58,
        21.88,
        20.77,
        16.48,
        21.35,
        17.77,
        17.25,
        18.83,
        17.36,
        19.24,
        18.83,
        17.25,
        16.6,
        17.77,
        16.72,
        19.18,
      ],
      type: "scatter",
      mode: "line+lines",
      visible: false,
      name: "2019<br />Masculino",
      line: { color: "rgba(141,160,203,1)", dash: "dash" },
      marker: {
        color: "rgba(141,160,203,1)",
        line: { color: "rgba(141,160,203,1)" },
      },
      textfont: { color: "rgba(141,160,203,1)" },
      error_y: { color: "rgba(141,160,203,1)" },
      error_x: { color: "rgba(141,160,203,1)" },
      xaxis: "x",
      yaxis: "y",
      frame: null,
    },
  ],
  highlight: {
    on: "plotly_click",
    persistent: false,
    dynamic: false,
    selectize: false,
    opacityDim: 0.2,
    selected: { opacity: 1 },
    debounce: 0,
  },
  shinyEvents: [
    "plotly_hover",
    "plotly_click",
    "plotly_selected",
    "plotly_relayout",
    "plotly_brushed",
    "plotly_brushing",
    "plotly_clickannotation",
    "plotly_doubleclick",
    "plotly_deselect",
    "plotly_afterplot",
    "plotly_sunburstclick",
  ],
  base_url: "https://plot.ly",
}
